<template>
  <b-sidebar id="sidebar-features" width="30em" right shadow no-header>
    <template #default="{ hide }">
      <div class="container-wrapper">
        <section class="page-header header">
          <div class="request-feature-title">Funcionalidades Solicitadas</div>
          <div class="actions">
            <Search class="search" @click="toggleSearch" />
            <Icon tooltip="Fechar">
              <Close class="close" @click="hide" />
            </Icon>
          </div>
        </section>

        <section v-if="showSearch" class="section-search">
          <b-form-input 
            placeholder="Digite o título, trecho do detalhe ou nome do usuário"
            v-model="search"
            @input="debouncingSearch"
          />
        </section>

        <SuggestForm 
          @onCreated="onCreated"
        />
        <div v-infinite-scroll="loadMore" :infinite-scroll-disabled="busy" infinite-scroll-distance="10">
          <section class="features">
              <SuggestFeature 
                v-for="feature in features" 
                :key="feature.id" 
                :feature="feature" 
                @onUpvote="onUpvote"
              />
          </section>
        </div>
        <div v-if="!features.length" class="empty-list">
          Nós queremos te ouvir, que tal sugerir algo novo?
        </div>
      </div>
    </template>
  </b-sidebar>
</template>
<script>
import api from '../../api'
import { debounce } from 'lodash'
import { getCurrentUser } from '@/utils/localStorageManager'

export default {
  components: {
    Icon: () => import('@/components/General/Icon'),
    Close: () => import('@/assets/icons/close.svg'),
    Search: () => import('@/assets/icons/search.svg'),
    SuggestForm: () => import('@/modules/featureRequests/components/SuggestForm'),
    SuggestFeature: () => import('@/modules/featureRequests/components/SuggestFeature'),
  },
  data() {
    return {
      loading: false,
      user: getCurrentUser(),
      showSearch: false,
      offset: 0,
      limit: 10,
      busy: false,
      features: [],
      debouncingSearch: debounce(this.searchDebounce, 500),
    }
  },
  methods: {
    onCreated(feature) {
      this.features.push(feature)
    },
    searchDebounce() {
      this.loading = true
      this.offset = 0
      api.getFeatureRequests(this.user.id, this.offset, this.limit, this.search)
      .then(({ data }) => {
        this.features = data
        data.length && (this.offset += this.limit)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        this.busy = false
        this.loading = false
      })
    },
    loadMore() {
      this.busy = true;
      api.getFeatureRequests(this.user.id, this.offset, this.limit, this.search)
      .then(({ data }) => {
        this.features = this.features.concat(data)
        data.length && (this.offset += this.limit)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        this.busy = false;
      })
    },
    onUpvote(feature) {
      feature.has_upvoted = true
      feature.upvotes += 1
      this.updateFeature(feature)

      api.upvoteFeatureRequest(this.user.id, feature.id)
        .then(() => {
          this.$toast.success('Voto computado com sucesso!')
        })
        .catch(err => {
          feature.has_upvoted = false
          feature.upvotes -= 1
          this.updateFeature(feature)
          this.$toast.error(err.message)
        })
    },
    updateFeature(feature) {
      const features = [...this.features]
      const index = features.findIndex((f) => f.id === feature.id)
      features[index] = {
        ...features[index],
        ...feature
      }
      this.features = features
    },
    toggleSearch() {
      this.search = null
      this.showSearch = !this.showSearch
    }
  }
}
</script>
<style lang="scss" scoped>
.container-wrapper {
  padding: 0 1rem;
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.request-feature-title {
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--dark-500);
}
.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.page-header {
  padding: 1rem 0;
}

.features {
  padding: 1rem 0;
  overflow-x: auto;
}
.close {
  width: 25px;
  fill: var(--neutral-500);
}
.search {
  width: 25px;
  stroke: var(--neutral-500);
  cursor: pointer;
}
.section-search {
  padding-bottom: 1rem;
}
.empty-list {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--type-placeholder);
}
</style>